import { DatePipe, DecimalPipe, TitleCasePipe } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ErrorHandler, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withInMemoryScrolling, withRouterConfig, withViewTransitions } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CustomRouterStateSerializer } from "@core/router/custom-router-state-serializer";
import { GlobalErrorHandlerService } from "@core/services/error-handler/global-error-handler.service";
import { CurrencyStore } from "@core/stores/currency/currency.store";
import { AnalyticsEffects } from "@features/analytics/effect/analytics.effects";
import { APP_ROUTES } from "@find-hotels-app/app.routes";
import { ARMY_API_CONFIG_TOKEN, ArmyServicesModule } from "@ihg/army-services";
import { AVAILABILITY_CONFIG_TOKEN, AvailabilityModule } from "@ihg/availability";
import { ShortNumberPipe } from "@ihg/custom-pipes";
import { FINANCE_CONFIG_TOKEN, FinanceServiceModule } from "@ihg/finance";
import { GQL_PROXY_CONFIG_TOKEN, GqlProxyServiceModule } from "@ihg/gql-proxy";
import { HOTEL_DETAILS_API_CONFIG, HotelDetailsServiceModule } from "@ihg/hotel-details";
import { CommonLogModule, LOG_CONFIG } from "@ihg/logging";
import { MEMBER_CONFIG_TOKEN, MembersServiceModule } from "@ihg/members";
import { RESERVATION_CONFIG_TOKEN, ReservationModule } from "@ihg/reservation";
import { ConfirmationService } from "@ihg/ui-components";
import { provideEffects } from "@ngrx/effects";
import { provideRouterStore } from "@ngrx/router-store";
import { provideStore } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { BaseDaoModule } from "ihg-ng-dao-service";
import { InlineSVGModule } from "ng-inline-svg";
import { NgxWebstorageModule } from "ngx-webstorage";

import { HttpWithCredentialsInterceptor } from "@shared/interceptors/HttpWithCredentialsInterceptor";
import { APP_CONFIG } from "@shared/services/config/app-config/app-config-key";
import { AppConfigService } from "@shared/services/config/app-config/app-config.service";
import { AppConfigFactory } from "@shared/services/config/app-config/factory/app-config-factory";
import { ViewNavigationEffects } from "@shared/services/navigation/effects/view-navigation-effects";
import { AppComponent } from "./app/app.component";
import { ROOT_REDUCERS } from "./app/app.reducers";
import { environment } from "./environments/environment";

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      APP_ROUTES,
      withViewTransitions(),
      withRouterConfig({
        onSameUrlNavigation: "reload",
      }),
      withInMemoryScrolling({ scrollPositionRestoration: "top" })
    ),
    provideStore(ROOT_REDUCERS, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    provideRouterStore({ serializer: CustomRouterStateSerializer }),
    provideEffects(AnalyticsEffects, ViewNavigationEffects),
    provideStoreDevtools({ maxAge: 10, connectInZone: true, logOnly: true }),
    importProvidersFrom(
      BrowserModule,
      InlineSVGModule.forRoot({ baseUrl: "/cdn/vendor/ihgimages/v1.0.0/" }),
      CommonLogModule.forRoot({
        provide: LOG_CONFIG,
        useFactory: AppConfigFactory.getLogConfig,
        deps: [AppConfigService],
      }),
      ReservationModule.forRoot({
        provide: RESERVATION_CONFIG_TOKEN,
        useFactory: AppConfigFactory.getApigeeApiConfig,
        deps: [AppConfigService],
      }),
      MembersServiceModule.forRoot({
        provide: MEMBER_CONFIG_TOKEN,
        useFactory: AppConfigFactory.getApigeeApiConfig,
        deps: [AppConfigService],
      }),
      AvailabilityModule.forRoot({
        provide: AVAILABILITY_CONFIG_TOKEN,
        useFactory: AppConfigFactory.getApigeeApiConfig,
        deps: [AppConfigService],
      }),
      GqlProxyServiceModule.forRoot({
        provide: GQL_PROXY_CONFIG_TOKEN,
        useFactory: AppConfigFactory.getGqlProxyConfig,
        deps: [AppConfigService],
      }),
      BaseDaoModule.forRoot(),
      HotelDetailsServiceModule.forRoot({
        provide: HOTEL_DETAILS_API_CONFIG,
        useFactory: AppConfigFactory.getHotelDetailsConfig,
        deps: [AppConfigService],
      }),
      ArmyServicesModule.forRoot({
        provide: ARMY_API_CONFIG_TOKEN,
        useFactory: AppConfigFactory.getApigeeApiConfig,
        deps: [AppConfigService],
      }),
      NgxWebstorageModule.forRoot({ prefix: "", separator: "", caseSensitive: true }),
      ServiceWorkerModule.register("/resources/global/find-hotels/ngsw-worker.js", {
        enabled: environment.production,
        scope: "/",
        registrationStrategy: "registerImmediately",
      }),
      FinanceServiceModule.forRoot({
        provide: FINANCE_CONFIG_TOKEN,
        useFactory: AppConfigFactory.getFinanceConfig,
        deps: [AppConfigService],
      })
    ),
    {
      provide: APP_CONFIG,
      useFactory: AppConfigFactory.getAppConfig,
      deps: [AppConfigService],
    },
    {
      provide: LOCALE_ID,
      useFactory: AppConfigFactory.getLocale,
      deps: [AppConfigService],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    DatePipe,
    DecimalPipe,
    ShortNumberPipe,
    TitleCasePipe,
    CurrencyStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpWithCredentialsInterceptor,
      multi: true,
    },
    ConfirmationService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.log(err));
