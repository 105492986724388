import { Injectable } from "@angular/core";
import { SORT_TYPES } from "@core/stores/hotel-sort/hotel-sort.constants";
import { HotelSortStore } from "@core/stores/hotel-sort/hotel-sort.store";
import { SessionStorageService } from "ngx-webstorage";

import { WindowService } from "@shared/services/util/window/window-service";

export const FOR_YOU_SORT_STORAGE_KEY = "FOR_YOU_SORT_STORAGE_KEY";

@Injectable({ providedIn: "root" })
export class EventListenerService {
  selectedType: SORT_TYPES;

  constructor(
    private hotelSortStore: HotelSortStore,
    private sessionStorageService: SessionStorageService,
    private windowService: WindowService
  ) {
    this.hotelSortStore.selectedType$.subscribe((type) => (this.selectedType = type));
    this.createSortListeners();
  }

  private createSortListeners(): void {
    this.createSortForYouHide();
    this.createSortForYouShow();
  }

  private createSortForYouHide() {
    this.windowService.document.addEventListener("SR_SORT_FOR_YOU_HIDE", () => {
      this.hotelSortStore.setForYouAvailable(false);
      if (this.selectedType === SORT_TYPES.FOR_YOU) {
        this.hotelSortStore.setSortType(SORT_TYPES.DISTANCE);
      }
      this.sessionStorageService.store(FOR_YOU_SORT_STORAGE_KEY, false);
    });
  }

  private createSortForYouShow() {
    this.windowService.document.addEventListener("SR_SORT_FOR_YOU_SHOW", () => {
      this.hotelSortStore.setForYouAvailable(true);
      this.hotelSortStore.setSortType(SORT_TYPES.FOR_YOU);
      this.sessionStorageService.store(FOR_YOU_SORT_STORAGE_KEY, true);
    });
  }
}
