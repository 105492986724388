import { Injectable } from "@angular/core";
import { Params, Router } from "@angular/router";
import { RouterFacadeService } from "@core/services/router-facade-service/router.facade";
import { CurrencyStore } from "@core/stores/currency/currency.store";
import { SearchCriteriaStore } from "@core/stores/search/search-criteria.store";
import { SortFilterCriteriaStore } from "@core/stores/search/sort-filter-criteria.store";
import { SearchFilterParamNames } from "@find-hotels-app/core/services/search-services/services/bi/search-filter-parser/models/search-filter-param-names";
import { SearchFilterParserService } from "@find-hotels-app/core/services/search-services/services/bi/search-filter-parser/search-filter-parser.service";
import { ROOM_SEARCH_ERROR_CODE } from "@find-hotels-app/core/services/select-room-services/contants/room-errors.constants";
import { SelectedRoomRateOffer } from "@find-hotels-app/shared/models/room-rate-offer.model";
import { CashAndPointsRate } from "@find-hotels-app/shared/models/room-rates.model";
import { RouteNames } from "@find-hotels-app/shared/models/route-names.enum";
import { IIbrMealPlanCodeAndQuantity, ISearchCriteria } from "@find-hotels-app/shared/models/search-criteria";
import { NonRoomInventoryListModel } from "@find-hotels-app/shared/models/shopping-cart.model";
import { CurrencyInfo } from "@find-hotels-app/shared/services/api/currency-conversion/models/currency-info.model";
import { SelectRoomRateAction } from "@find-hotels-app/shared/services/navigation/actions/select-room-rate-action";
import { ShoppingCartService } from "@find-hotels-app/shared/services/session-state/shopping-cart.service";
import { StorageService } from "@find-hotels-app/shared/services/storage/storage-service";
import { LogService } from "@ihg/logging";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";
import { Action } from "@ngrx/store";
import { RedirectService } from "@pages/select-room-rate/services/redirect/redirect.service";
import { of } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";

import { SortFilterCriteria } from "@shared/models/sort-filter-criteria";
import { NoopAction } from "@shared/services/navigation/actions/noop-action";
import { SearchResultMapReloadAction } from "@shared/services/navigation/actions/search-result-map-reload-action";
import { UpdateCorporateIdAction } from "@shared/services/navigation/actions/update-corporate-id-action";
import { RedirectToCRRAction } from "../actions/redirect-to-crr-action";
import { SelectEnhanceStayAction } from "../actions/select-enhance-stay-action";
import { SelectHotelAction } from "../actions/select-hotel-action";
import { SelectPriceTypeAction } from "../actions/select-search-result-price-type-action";
import { ViewNavigationAction } from "../actions/view-navigation-action";
import { ViewNavigationActionType } from "../actions/view-navigation-action.enum";
import { ViewNavigationModel } from "../models/view-navigation-model";

@Injectable({
  providedIn: "root",
})
export class ViewNavigationEffects {
  static CLASS_NAME = "ViewNavigationEffects";

  routerNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.VIEW_NAVIGATION_NAVIGATE_TO_NEXT_STATE]),
        tap((action: ViewNavigationAction) => {
          const params: ViewNavigationModel = action.navigationParams;
          this.router.navigateByUrl(this.router.createUrlTree([params.path], { queryParams: params.params }));
        })
      ),
    { dispatch: false }
  );

  updateCorporateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.UPDATE_CORPORATE_ID]),
      withLatestFrom(this.routerFacadeService.queryParams$, this.routerFacadeService.route$),
      map(([action, params, route]: [UpdateCorporateIdAction, Params, string]) => {
        const profileCorporateId = action.corporateId;
        const queryParamsHasCorporateId: boolean = params[SearchFilterParamNames.CORPORATE_ID] !== undefined;
        if (!queryParamsHasCorporateId) {
          const { preferredRateCodes, corporateId } = this.searchCriteriaStore.searchCriteria;
          const updateNeeded = !(preferredRateCodes?.length > 0 || corporateId?.length > 0);
          if (updateNeeded) {
            return new ViewNavigationAction({ path: route, params: { ...params, qCpid: profileCorporateId } });
          }
        }
        return new NoopAction();
      })
    )
  );

  selectEnhanceStay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SELECT_ENHANCE_STAY_REDIRECT]),
        withLatestFrom(this.currencyStore.currentCurrency$, this.currencyStore.availableCurrencies$),
        map(([action, currentCurrency, availableCurrencies]) => {
          const { productList, upsellProduct, searchCriteria, redirectToEys } = action as SelectEnhanceStayAction;
          const BPRProduct = productList.find((x) => x.isBonusPointsRate);
          const selectedRoomRate = <SelectedRoomRateOffer>{
            rateCode: BPRProduct?.ratePlanCode ?? searchCriteria.rateCode,
            roomCode: upsellProduct?.productCode ?? searchCriteria.roomCode,
          };
          const nonRoomInventoryList: NonRoomInventoryListModel[] = productList
            .filter((x) => x.isBonusPointsRate === false)
            .map((product) => ({
              productCode: product.productCode,
              productTypeCode: product.productTypeCode,
              name: product.title,
              pricingFrequency: product.pricingFrequency,
              pricingMethod: product.pricingMethod,
              quantity: product.quantity,
              baseAmount: product.price,
              ratePlanCode: product.ratePlanCode,
              taxes: product.taxes,
              fees: product.fees,
              totalTax: product.tax,
              totalFee: product.serviceCharge,
              ratePlanName: product.rateName,
              cashBeforeTax: product.priceBeforeTax,
              purchaseLimit: product.purchaseLimit,
            }));

          this.shoppingCartService.add(
            selectedRoomRate,
            searchCriteria,
            currentCurrency,
            availableCurrencies,
            nonRoomInventoryList,
            upsellProduct,
            "eys",
            true
          );
          if (redirectToEys) {
            this.redirectService.redirectToStayMgmt(searchCriteria.confirmationNumber, searchCriteria.guestLastName);
          } else {
            this.redirectService.redirectToPay();
          }
        })
      ),
    { dispatch: false }
  );

  skipEnhanceStayToPay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SKIP_REDIRECT_TO_PAY]),
        withLatestFrom(
          this.searchCriteriaStore.searchCriteria$,
          this.currencyStore.currentCurrency$,
          this.currencyStore.availableCurrencies$
        ),
        map(([, searchCriteria, currentCurrency, availableCurrencies]) => {
          const selectedRoomRate = <SelectedRoomRateOffer>{
            rateCode: searchCriteria.rateCode,
            roomCode: searchCriteria.roomCode,
          };
          this.shoppingCartService.add(
            selectedRoomRate,
            searchCriteria,
            currentCurrency,
            availableCurrencies,
            [],
            null,
            "eys"
          );
          this.redirectService.redirectToPay();
        })
      ),
    { dispatch: false }
  );

  selectRoomRate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SELECT_ROOM_RATE_REDIRECT]),
        withLatestFrom(
          this.searchCriteriaStore.searchCriteria$,
          this.sortFilterCriteriaStore.sortFilterCriteria$,
          this.currencyStore.currentCurrency$,
          this.currencyStore.availableCurrencies$,
          this.routerFacadeService.getUrl$
        ),
        map(
          ([action, searchCriteria, sortFilterCriteria, currentCurrency, availableCurrencies, url]: [
            SelectRoomRateAction,
            ISearchCriteria,
            SortFilterCriteria,
            string,
            CurrencyInfo[],
            string,
          ]) => {
            const redirectToStay = url.includes(RouteNames.SELECT_ROOM_RATE_CHANGE);
            const roomRate = action.roomRate;
            const searchCriteriaWithRateAndRoom = <ISearchCriteria>{
              ...searchCriteria,
              roomCode: roomRate.room.code,
              rateCode: roomRate.rate.code,
              roomProductCode: roomRate.rate.offer.room.productCode,
            };

            if (roomRate.rate.offer.rate.isPreDefinedPackage) {
              const mealPlans: IIbrMealPlanCodeAndQuantity[] = [];
              roomRate.rate.offer.includedProducts.forEach((product) => {
                mealPlans.push({
                  inventoryTypeCode: product.inventoryTypeCode,
                  quantity: product.quantity,
                  productCode: product.productCode,
                });
              });
              searchCriteriaWithRateAndRoom.ibrMealPlanCodesAndQuantities = mealPlans;
            }

            const selectedRoomOffer: SelectedRoomRateOffer = {
              rateCode: roomRate.rate.code,
              id: roomRate.rate.isPointsAndCashRate() ? (roomRate.rate as CashAndPointsRate).id : false,
              roomCode: roomRate.room.code,
            };

            this.shoppingCartService.add(
              selectedRoomOffer,
              searchCriteriaWithRateAndRoom,
              currentCurrency,
              availableCurrencies,
              []
            );
            const isEYSRedirect = redirectToStay === false;

            if (isEYSRedirect) {
              const params = this.searchFilterParserService.getParams(
                searchCriteriaWithRateAndRoom,
                sortFilterCriteria
              );
              this.redirectService.redirectToEYS(params);
            } else {
              this.storageService.setSelectedRoomRate(searchCriteriaWithRateAndRoom.confirmationNumber);
              this.redirectService.redirectToStayMgmt(
                searchCriteriaWithRateAndRoom.confirmationNumber,
                searchCriteriaWithRateAndRoom.guestLastName
              );
            }
          }
        ),
        catchError((error: Error) => {
          this.logService.error("Error retrieving NRI information.", ViewNavigationEffects.CLASS_NAME, error);
          this.redirectService.redirectToPay();
          return of(false);
        })
      ),
    { dispatch: false }
  );

  skipEnhanceStayToModify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SKIP_REDIRECT_TO_MODIFY]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$),
        tap(([, searchCriteria]) => {
          this.redirectService.redirectToStayMgmt(searchCriteria.confirmationNumber, searchCriteria.guestLastName);
        })
      ),
    { dispatch: false }
  );

  skipEnhanceStayToResCon$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SKIP_REDIRECT_TO_RESCON]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$),
        map(([, searchCriteria]) => {
          this.redirectService.redirectToResConPage(searchCriteria.confirmationNumber, searchCriteria.guestLastName);
        })
      ),
    { dispatch: false }
  );

  navigatedSuccessfullyEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        tap(() => {
          this.logService.postLogs();
        })
      ),
    { dispatch: false }
  );

  loadSelectAHotelPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType.SELECT_HOTEL_REDIRECT),
        withLatestFrom(this.routerFacadeService.queryParams$),
        tap(([action, params]: [SelectHotelAction, Params]) => {
          let showNoRoomAvailError = false;
          const queryParams = params;
          const hotelMnemonic = queryParams[SearchFilterParamNames.MNEMONIC] ?? null;
          if (hotelMnemonic) {
            queryParams[SearchFilterParamNames.PREFERRED_MNEMONIC] = hotelMnemonic;
            delete queryParams[SearchFilterParamNames.MNEMONIC];
          }
          if (action.errorCode === ROOM_SEARCH_ERROR_CODE.INVALID_CAPACITY_OF_ROOMS) {
            showNoRoomAvailError = true;
          }
          if (action.removeRate) {
            queryParams[SearchFilterParamNames.PREFERRED_RATES] = null;
          }
          this.router.navigate([RouteNames.SEARCH_RESULT], {
            queryParams,
            state: { showNoRoomAvailError: showNoRoomAvailError },
          });
        })
      ),
    { dispatch: false }
  );

  searchResultPriceType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SELECT_PRICE_TYPE]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$, this.sortFilterCriteriaStore.sortFilterCriteria$),
        tap(([action, searchCriteria, sortFilterCriteria]) => {
          const priceType = (action as SelectPriceTypeAction).priceType;
          const preferredRate = (action as SelectPriceTypeAction).preferredRate;
          const searchCriteriaWithPriceType = {
            ...searchCriteria,
            preferredRateCodes: [preferredRate],
          };
          const sortCriteriaWithPriceType = {
            ...sortFilterCriteria,
            priceType: priceType,
          };

          const params = this.searchFilterParserService.getParams(
            searchCriteriaWithPriceType,
            sortCriteriaWithPriceType
          );
          this.redirectService.redirectToSearchResult(params);
        })
      ),
    { dispatch: false }
  );

  searchResultMapReload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.SEARCH_RESULT_MAP_RELOAD]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$, this.sortFilterCriteriaStore.sortFilterCriteria$),
        tap(([action, searchCriteria, sortFilterCriteria]) => {
          const destination = (action as SearchResultMapReloadAction).destination;
          const searchCriteriaWithPriceType = {
            ...searchCriteria,
            destination: destination,
          };
          const params = this.searchFilterParserService.getParams(searchCriteriaWithPriceType, sortFilterCriteria);
          this.redirectService.redirectToSearchResult(params);
        })
      ),
    { dispatch: false }
  );

  redirectToCRR$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(ViewNavigationActionType[ViewNavigationActionType.REDIRECT_TO_CRR]),
        withLatestFrom(this.searchCriteriaStore.searchCriteria$, this.sortFilterCriteriaStore.sortFilterCriteria$),
        tap(([action, searchCriteria, sortFilterCriteria]) => {
          const hotelCode = (action as RedirectToCRRAction).hotelCode;
          const checkInDate = (action as RedirectToCRRAction).checkInDate;
          const checkOutDate = (action as RedirectToCRRAction).checkOutDate;
          const removeRate = (action as RedirectToCRRAction).removeRate ?? false;
          if (hotelCode) {
            searchCriteria.hotelMnemonic = hotelCode;
          }
          if (checkInDate) {
            searchCriteria.checkInDate = checkInDate;
          }
          if (checkOutDate) {
            searchCriteria.checkOutDate = checkOutDate;
          }
          if (removeRate) {
            searchCriteria.preferredRateCodes = [];
          }
          const params = this.searchFilterParserService.getParams(searchCriteria, sortFilterCriteria);
          this.redirectService.redirectToCRR(params);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<ViewNavigationAction>,
    private logService: LogService,
    private redirectService: RedirectService,
    public router: Router,
    public storageService: StorageService,
    public shoppingCartService: ShoppingCartService,
    public searchFilterParserService: SearchFilterParserService,
    public currencyStore: CurrencyStore,
    private routerFacadeService: RouterFacadeService,
    private searchCriteriaStore: SearchCriteriaStore,
    private sortFilterCriteriaStore: SortFilterCriteriaStore
  ) {}
}
