import { V3Schema } from "@ihg/availability";

import { IIbrMealPlanCodeAndQuantity } from "@shared/models/search-criteria";

export interface ShoppingCartModel {
  items: ShoppingCartItem[];
}

export type BackPage = "crr" | "eys";

export interface ShoppingCartItem {
  itemType: ShoppingCartItemType;
  creationTime: number; // new Date().getTime()
  checkinDate: string; // mm/dd/YYYY
  checkoutDate: string;
  corpId: string | null;
  freeNightId: string | null;
  groupCode: string | null;
  hotelId: string;
  iataNumber: string | null;
  numAdults: number;
  numChildren: number;
  childrenAges: number[];
  numRooms: number;
  pointsAndCashId: string | false;
  rateCode: string;
  reservationId: string | null;
  roomCode: string;
  nonRoomInventoryList: NonRoomInventoryListModel[];
  roomUpsellProduct: RoomUpsellProduct;
  selectedCurrency: SelectedCurrencyInfo;
  backPage: BackPage;
  eysChanges?: boolean;
  roomProductCode?: string;
  ibrMealPlanCodesAndQuantities?: IIbrMealPlanCodeAndQuantity[];
  downgradedRoomCode?: string;
  downgradedRateCode?: string;
}

export interface SelectedCurrencyInfo {
  code: string;
  name: string;
  symbol: string;
}

export enum ShoppingCartItemType {
  HOTEL_RESERVATION = "hotelreservation",
}

export interface NonRoomInventoryListModel {
  name: string;
  pricingFrequency: V3Schema.PricingFrequency;
  productCode: string;
  productTypeCode: string;
  quantity: number;
  baseAmount: number;
  ratePlanCode: string;
  totalTax: number;
  totalFee: number;
  ratePlanName: string;
  cashBeforeTax: number;
  purchaseLimit: number;
}
export interface RoomUpsellProduct {
  name: string;
  pricingFrequency: string;
  productCode: string;
  upsellReason: V3Schema.UpsellReasonType;
  priceDifference: number;
  taxDifference: number;
}
