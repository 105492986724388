import { Inject, Injectable } from "@angular/core";
import { WindowService } from "@find-hotels-app/shared/services/util/window/window-service";
import { LogService, LogServiceInterface } from "@ihg/logging";

import { DataTrackingAppServiceInterface } from "./data-tracking-app-service.interface";

@Injectable({ providedIn: "root" })
export class DataTrackingAppService implements DataTrackingAppServiceInterface {
  constructor(
    @Inject(LogService) private logService: LogServiceInterface,
    private windowService: WindowService
  ) {}

  analyticsEnsightenEvents(eventName: string): void {
    try {
      if (this.windowService.isFunctionDefined()) {
        this.windowService.getFunction()({
          site_events: { [eventName]: "true" },
        });
      } else {
        this.logService.warn(
          " Ensighten Error: window." +
            "_trackAnalytics" +
            " is not a function. Cannot simulate " +
            eventName +
            " event",
          "data-tracking-app"
        );
      }
    } catch (e) {
      this.logService.error(" Ensighten Error: " + JSON.stringify(e), "data-tracking-app");
    }
  }
}
