import { Injectable } from "@angular/core";
import { ShoppingCartService as NewShoppingCartService } from "@ihg/common";
import { SessionStorageService } from "ngx-webstorage";

import { RoomUpgradeProduct } from "@shared/models/enhance-stay-product.model";
import { SelectedRoomRateOffer } from "@shared/models/room-rate-offer.model";
import { ISearchCriteria } from "@shared/models/search-criteria";
import { CurrencyInfo } from "@shared/services/api/currency-conversion/models/currency-info.model";
import { ShoppingCartItemBuilderService } from "@shared/services/session-state/builders/shopping-cart-item-builder.service";
import {
  BackPage,
  NonRoomInventoryListModel,
  ShoppingCartItem,
  ShoppingCartModel,
} from "../../models/shopping-cart.model";
import { ShoppingCartServiceInterface } from "./shopping-cart.service.interface";

const SHOPPING_CART_KEY = "shoppingCart";

@Injectable({
  providedIn: "root",
})
export class ShoppingCartService implements ShoppingCartServiceInterface {
  constructor(
    private sessionStorageService: SessionStorageService,
    private shoppingCartItemBuilderService: ShoppingCartItemBuilderService,
    private newShoppingCartService: NewShoppingCartService
  ) {}

  add(
    selectedRoomRateOffer: SelectedRoomRateOffer,
    searchCriteria: ISearchCriteria,
    currentCurrency: string,
    availableCurrencies: CurrencyInfo[],
    nonRoomInventoryList: NonRoomInventoryListModel[],
    upsellProduct: RoomUpgradeProduct = null,
    backPage: BackPage = "crr",
    eysChanges = false
  ): void {
    this.addToCart(
      this.shoppingCartItemBuilderService.buildHotelReservationItem(
        selectedRoomRateOffer,
        searchCriteria,
        currentCurrency,
        availableCurrencies,
        nonRoomInventoryList,
        upsellProduct,
        backPage,
        eysChanges
      )
    );

    this.newShoppingCartService.save(
      this.shoppingCartItemBuilderService.buildShoppingCart(
        selectedRoomRateOffer,
        searchCriteria,
        currentCurrency,
        availableCurrencies,
        nonRoomInventoryList,
        upsellProduct,
        backPage
      )
    );
  }

  addToCart(item: ShoppingCartItem): void {
    this.clearCart();
    const shoppingCart = this.getCart();
    shoppingCart.items.push(item);
    this.sessionStorageService.store(SHOPPING_CART_KEY, shoppingCart);
  }

  clearCart(): void {
    this.sessionStorageService.clear(SHOPPING_CART_KEY);
  }

  getCart(): ShoppingCartModel {
    const shoppingCart = this.sessionStorageService.retrieve(SHOPPING_CART_KEY);
    return <ShoppingCartModel>(shoppingCart ? shoppingCart : { items: <ShoppingCartItem[]>[] });
  }

  removeFromCart(item: ShoppingCartItem): void {
    const shoppingCart = this.getCart();
    if (!!item && shoppingCart.items && shoppingCart.items.length && shoppingCart.items.indexOf(item) > -1) {
      shoppingCart.items.splice(shoppingCart.items.indexOf(item), 1);
    }
    this.sessionStorageService.store(SHOPPING_CART_KEY, shoppingCart);
  }
}
