import { RouterState } from "@core/models/router-state.model";
import { AnalyticsState } from "@features/analytics/models/app-state.model";
import { analyticsReducer } from "@features/analytics/reducers/analytics.reducer";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducerMap } from "@ngrx/store";

export interface State {
  router: RouterReducerState<RouterState>;
  analytics: AnalyticsState;
}

export const ROOT_REDUCERS: ActionReducerMap<State> = {
  router: routerReducer,
  analytics: analyticsReducer,
};
