import { Injectable } from "@angular/core";
import { RoomUpgradeProduct } from "@find-hotels-app/shared/models/enhance-stay-product.model";
import { SelectedRoomRateOffer } from "@find-hotels-app/shared/models/room-rate-offer.model";
import { ShoppingCart } from "@ihg/common";

import { ISearchCriteria } from "@shared/models/search-criteria";
import {
  BackPage,
  NonRoomInventoryListModel,
  RoomUpsellProduct,
  SelectedCurrencyInfo,
  ShoppingCartItem,
  ShoppingCartItemType,
} from "@shared/models/shopping-cart.model";
import { CurrencyInfo } from "../../api/currency-conversion/models/currency-info.model";
import { ShoppingCartItemBuilderServiceInterface } from "./shopping-cart-item-builder.service.interface";

interface IGuestCount {
  otaCode: "AQC10" | "AQC8" | "AQC7";
  count: number;
  age?: number;
}

@Injectable({
  providedIn: "root",
})
export class ShoppingCartItemBuilderService implements ShoppingCartItemBuilderServiceInterface {
  buildShoppingCart(
    selectedRoomRateOffer: SelectedRoomRateOffer,
    searchCriteria: ISearchCriteria,
    currentCurrency: string,
    availableCurrencies: CurrencyInfo[],
    nonRoomInventoryList: NonRoomInventoryListModel[],
    upsellProduct: RoomUpgradeProduct = null,
    backPage: BackPage = "crr"
  ): ShoppingCart {
    const isRateFree = this.shouldSetFreeNight(searchCriteria, selectedRoomRateOffer);
    const isGroupRate = this.shouldSetGroupCode(searchCriteria, selectedRoomRateOffer);
    const adults: IGuestCount = {
      otaCode: "AQC10",
      count: searchCriteria.rooms[0].numberOfAdults,
    };
    const children: IGuestCount[] = (searchCriteria.rooms[0].childrenAges ?? []).map((age) => {
      if (age !== null && age >= 0) {
        return {
          otaCode: "AQC8",
          count: 1,
          age: age,
        };
      } else {
        return {
          otaCode: "AQC8",
          count: 1,
        };
      }
    });
    const guestCounts = [adults, ...children];
    return new ShoppingCart({
      hotel: {
        mnemonic: searchCriteria.hotelMnemonic,
      },
      period: {
        checkIn: searchCriteria.checkInDate.toISOString().split("T")[0],
        checkOut: searchCriteria.checkOutDate.toISOString().split("T")[0],
      },
      mainOffers: [
        {
          id: 0,
          available: true,
          mainProduct: {
            quantity: searchCriteria.rooms[0].numberOfRooms,
            inventoryTypeCode: selectedRoomRateOffer.roomCode,
            inventoryTypeName: "",
            downgradedCode: searchCriteria.roomCode,
            productCode: "SR",
          },
          additionalProducts: (searchCriteria.ibrMealPlanCodesAndQuantities ?? []).map((product) => ({
            quantity: product.quantity,
            inventoryTypeCode: product.inventoryTypeCode,
            productCode: product.productCode,
          })),
          upsellProduct:
            upsellProduct !== null
              ? {
                  name: upsellProduct.productName,
                  pricingFrequency: upsellProduct.pricingFrequency,
                  productCode: upsellProduct.productCode,
                  upsellReason: upsellProduct.upsellReason,
                  priceDifference: upsellProduct.cashBeforeTax,
                  taxDifference: upsellProduct.tax,
                }
              : undefined,
          rate: {
            code: isGroupRate ? searchCriteria.groupCode : selectedRoomRateOffer.rateCode,
            offerId: isRateFree && searchCriteria.freeNightType ? searchCriteria.freeNightType : "",
            depositId: selectedRoomRateOffer.id === false ? "" : selectedRoomRateOffer.id,
            downgradedCode: this.downgradedRateCode(searchCriteria),
          },
          guestCounts: guestCounts,
          corporateAccount: searchCriteria.corporateId ?? "",
          crossSellOffers: (nonRoomInventoryList ?? []).map((product) => ({
            product: {
              quantity: product.quantity,
              inventoryTypeCode: product.productCode,
              productCode: product.productTypeCode,
            },
            rate: {
              code: product.ratePlanCode,
            },
            guestCounts: guestCounts,
          })),
        },
      ],
      travelAgencyNumber: searchCriteria.iataNumber ?? "",
      currency: this.getSelectedCurrencyInfo(currentCurrency, availableCurrencies)?.code ?? "",
      backPage: backPage,
    });
  }

  buildHotelReservationItem(
    selectedRoomRateOffer: SelectedRoomRateOffer,
    searchCriteria: ISearchCriteria,
    currentCurrency: string,
    availableCurrencies: CurrencyInfo[],
    nonRoomInventoryList: NonRoomInventoryListModel[],
    upsellProduct: RoomUpgradeProduct = null,
    backPage: BackPage = "crr",
    eysChanges = false
  ): ShoppingCartItem {
    const isRateFree = this.shouldSetFreeNight(searchCriteria, selectedRoomRateOffer);
    const isGroupRate = this.shouldSetGroupCode(searchCriteria, selectedRoomRateOffer);
    let roomUpsellProduct: RoomUpsellProduct = null;
    if (upsellProduct !== null) {
      roomUpsellProduct = {
        name: upsellProduct.productName,
        pricingFrequency: upsellProduct.pricingFrequency,
        productCode: upsellProduct.productCode,
        upsellReason: upsellProduct.upsellReason,
        priceDifference: upsellProduct.cashBeforeTax,
        taxDifference: upsellProduct.tax,
      };
    }
    return <ShoppingCartItem>{
      itemType: ShoppingCartItemType.HOTEL_RESERVATION,
      creationTime: this.getCreationTime(),
      checkinDate: this.getShoppingCartDateString(searchCriteria.checkInDate),
      checkoutDate: this.getShoppingCartDateString(searchCriteria.checkOutDate),
      hotelId: searchCriteria.hotelMnemonic,
      corpId: searchCriteria.corporateId || null,
      freeNightId: isRateFree && searchCriteria.freeNightType ? searchCriteria.freeNightType : null,
      groupCode: isGroupRate ? searchCriteria.groupCode : null,
      iataNumber: searchCriteria.iataNumber || null,
      numAdults: searchCriteria.rooms[0].numberOfAdults,
      numChildren: searchCriteria.rooms[0].numberOfChildren,
      childrenAges: searchCriteria.rooms[0].childrenAges,
      numRooms: searchCriteria.rooms[0].numberOfRooms,
      pointsAndCashId: selectedRoomRateOffer.id,
      rateCode: selectedRoomRateOffer.rateCode,
      roomCode: selectedRoomRateOffer.roomCode,
      reservationId: searchCriteria.confirmationNumber ?? null, // placeholder for stay mgmt (change)
      nonRoomInventoryList: nonRoomInventoryList ?? [],
      roomUpsellProduct: roomUpsellProduct,
      selectedCurrency: this.getSelectedCurrencyInfo(currentCurrency, availableCurrencies),
      backPage: backPage,
      eysChanges,
      roomProductCode: searchCriteria.roomProductCode ?? "",
      ibrMealPlanCodesAndQuantities: searchCriteria.ibrMealPlanCodesAndQuantities ?? [],
      downgradedRoomCode: searchCriteria.roomCode,
      downgradedRateCode: this.downgradedRateCode(searchCriteria),
    };
  }

  downgradedRateCode(searchCriteria: ISearchCriteria) {
    if (searchCriteria.rateCode) {
      return searchCriteria.rateCode;
    }
    if (searchCriteria.groupCode) {
      return searchCriteria.groupCode;
    }
    return "";
  }

  shouldSetGroupCode(searchCriteria: ISearchCriteria, selectedRoomRateOffer: SelectedRoomRateOffer): boolean {
    return (
      searchCriteria.groupCode &&
      selectedRoomRateOffer.rateCode.toUpperCase() === searchCriteria.groupCode.toUpperCase()
    );
  }

  shouldSetFreeNight(searchCriteria: ISearchCriteria, selectedRoomRateOffer: SelectedRoomRateOffer): boolean {
    return (
      searchCriteria.freeNightRateCode &&
      searchCriteria.freeNightRateCode.toUpperCase() === selectedRoomRateOffer.rateCode.toUpperCase()
    );
  }

  /**
   * Returns date in correct format for shopping cart: m/d/YYYY, eg 6/7/2021 for ISO date 2021-01-13
   * @param date
   */
  getShoppingCartDateString(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  getCreationTime(): number {
    return new Date().getTime();
  }

  getSelectedCurrencyInfo(currentCurrency: string, availableCurrencies: CurrencyInfo[] = []): SelectedCurrencyInfo {
    const currency = availableCurrencies && availableCurrencies.find((ac) => ac.code === currentCurrency);
    if (currency) {
      return <SelectedCurrencyInfo>{
        code: currency.code,
        name: currency.name,
        symbol: currency.symbol,
      };
    } else {
      return <SelectedCurrencyInfo>{};
    }
  }
}
