import { Injectable } from "@angular/core";
import { PageLoadAction } from "@features/analytics/actions/page-load.action";
import { PartialUpdateAction } from "@features/analytics/actions/partial-update.action";
import { AnalyticsDataBuilderService } from "@features/analytics/builders/analytics-data-builder.service";
import { AnalyticsState } from "@features/analytics/models/app-state.model";
import * as fromAnalytics from "@features/analytics/reducers/analytics.reducer";
import { AppConfigService } from "@find-hotels-app/shared/services/config/app-config/app-config.service";
import { StorageService } from "@find-hotels-app/shared/services/storage/storage-service";
import { DataTrackingAppService } from "@find-hotels-app/shared/services/util/data-tracking-app/data-tracking-app.service";
import { MediaQueryService } from "@find-hotels-app/shared/services/util/media-query/media-query.service";
import { WindowService } from "@find-hotels-app/shared/services/util/window/window-service";
import { AkamaiService, Brand } from "@ihg/common";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, tap, withLatestFrom } from "rxjs/operators";

import { BrowserDetectionService } from "@shared/services/util/browser-detection/browser-detection.service";
import { AnalyticsActionType } from "../actions/analytics-action-type.enum";
import { AppStateAction, TrackingType } from "../actions/app-state.action";
import { checkEnv, EDW_SELL_SOURCE_MAP } from "../constants/analytics.constants";

@Injectable()
export class AnalyticsEffects {
  constructor(
    private store$: Store<fromAnalytics.State>,
    private actions$: Actions,
    private appConfigService: AppConfigService,
    private akamaiService: AkamaiService,
    public storageService: StorageService,
    private browserDetectionService: BrowserDetectionService,
    private windowService: WindowService,
    private analyticsDataBuilderService: AnalyticsDataBuilderService,
    private dataTrackingAppService: DataTrackingAppService,
    private mediaQueryService: MediaQueryService
  ) {}

  commonData$: Observable<AppStateAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActionType.COMMON_DATA),
      map(() => {
        const analyticsState: AnalyticsState = {
          timestamp: Date.now().toString(),
          url: this.windowService.href,
          akamaiCountryCode: this.akamaiService.countryCode,
          akamaiRegion: this.akamaiService.region,
          akamaiSubRegion: this.akamaiService.subRegion,
          edwSellSource: EDW_SELL_SOURCE_MAP[this.browserDetectionService.deviceType],
          appName: this.appConfigService.config.appName,
          isAMER: "AMER" === this.akamaiService.region,
          isAMEA: "AMEA" === this.akamaiService.region,
          isEUR: "EUR" === this.akamaiService.region,
          isGC: "GC" === this.akamaiService.region,
          triggerTracking: false,
          browserInfo: {
            browser: this.browserDetectionService.browser,
            browserVersion: this.browserDetectionService.browserVersion,
            device: this.browserDetectionService.device,
            deviceType: this.browserDetectionService.deviceType,
            userAgent: this.browserDetectionService.userAgent,
          },
          countryCode: this.akamaiService.countryCode,
          region: this.akamaiService.region,
          subRegion: this.akamaiService.subRegion,
          isTablet: this.akamaiService.isTablet,
          isWirelessDevice: this.akamaiService.isWirelessDevice,
          latitude: this.akamaiService.latitude,
          longitude: this.akamaiService.longitude,
          clientIP: this.akamaiService.clientIP,
          iata: this.storageService.getIATA() || "",
          glat: this.storageService.getGLAT() || "",
          beFreeCookieCreationDate: this.storageService.getBFreeCreationDate() || "",
          country: this.akamaiService.countryCode,
          language: (this.storageService.getCountryLanguage() || "").split("$:")[1] || "",
          siteBrand: Brand.analyticsBrandCode(
            this.appConfigService.config.brand.brandCode,
            this.appConfigService.config.brand.fourLetterBrandCode
          ),
          siteLanguage: this.appConfigService.config.language.toLowerCase(),
          siteCountry: this.appConfigService.config.country.toUpperCase(),
          categoryID: [
            Brand.analyticsBrandCode(
              this.appConfigService.config.brand.brandCode,
              this.appConfigService.config.brand.fourLetterBrandCode
            ),
            this.appConfigService.config.country,
            this.appConfigService.config.language,
          ].join(","),
          envName: checkEnv(this.windowService.host),
          sessionId: this.storageService.getIHGAppTrackingSession(),
          loginType: "ANONYMOUS",
          pcrNumber: "",
          isEmployee: "",
          membershipStatus: "",
          membershipStatusCode: "",
          ambassador: "FALSE",
          karmaMember: "FALSE",
          viewport: this.mediaQueryService.viewport,
          orientation: this.mediaQueryService.orientation,
          isBot: this.storageService.getIhgIsBot(),
        };
        return new AppStateAction(analyticsState, TrackingType.NONE);
      })
    )
  );

  pageLoaded$: Observable<AppStateAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActionType.PAGE_LOAD),
      map((action: PageLoadAction) => {
        const analyticsState: AnalyticsState = {
          type: action.pageInfo.type,
          controllerName: action.pageInfo.controllerName,
          timestamp: Date.now().toString(),
          mapLoaded: false,
          url: this.windowService.href,
          ...this.analyticsDataBuilderService.buildWithQueryParamsState(),
        };
        return new AppStateAction(analyticsState, TrackingType.BASE);
      })
    )
  );

  partialUpdate$: Observable<AppStateAction> = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalyticsActionType.PARTIAL_UPDATE),
      map((action: PartialUpdateAction) => {
        const analyticsState: AnalyticsState = {
          ...action.state,
          timestamp: Date.now().toString(),
        };
        return new AppStateAction(analyticsState, action.trackingType);
      })
    )
  );

  saveAndPublish$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(AnalyticsActionType.SET_APP_STATE),
        withLatestFrom(this.store$.select(fromAnalytics.selectAnalyticsState)),
        tap(([action, analyticsState]) => {
          // // set the analytics object into the current tracking json global variable
          this.windowService.trackingJson = {};
          this.windowService.trackingJson = analyticsState;

          /**
           * Call and send events to Ensighten
           */
          // called when data layer is partially ready
          if ((action as AppStateAction).trackingType === TrackingType.BASE) {
            this.dataTrackingAppService.analyticsEnsightenEvents("base_tracking_json_ready");
          }
          // called when profile data is completely ready
          if ((action as AppStateAction).trackingType === TrackingType.PROFILE) {
            this.dataTrackingAppService.analyticsEnsightenEvents("profile_tracking_json_ready");
          }
          // called when data layer is completely ready
          if ((action as AppStateAction).trackingType === TrackingType.VIEW_PAGE) {
            this.dataTrackingAppService.analyticsEnsightenEvents("virtual_page_view");
          }
        })
      ),
    { dispatch: false }
  );
}
