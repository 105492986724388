import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { WINDOW } from "@ihg/common";
import { LogService } from "@ihg/logging";

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(
    private logService: LogService,
    @Inject(WINDOW) private _window: Window
  ) {}

  handleError(error: Error) {
    const message = error?.message ?? "UNKNOWN_MESSAGE";
    const stack = error?.stack ?? "UNKNOWN_STACKTRACE";
    this.logService.error("Uncaught error", "GlobalErrorHandlerService", message, this._window.location.href, stack);
  }
}
