import { registerLocaleData } from "@angular/common";
import arAE from "@angular/common/locales/ar-AE";
import deDE from "@angular/common/locales/de";
import enUS from "@angular/common/locales/en";
import enGB from "@angular/common/locales/en-GB";
import esES from "@angular/common/locales/es";
import esUS from "@angular/common/locales/es-US";
import frFR from "@angular/common/locales/fr";
import idID from "@angular/common/locales/id"; // Indonesian
import itIT from "@angular/common/locales/it";
import jaJP from "@angular/common/locales/ja";
import koKR from "@angular/common/locales/ko";
import nlNL from "@angular/common/locales/nl";
import plPl from "@angular/common/locales/pl";
import ptBr from "@angular/common/locales/pt";
import ptPT from "@angular/common/locales/pt-PT";
import ruRU from "@angular/common/locales/ru";
import thTh from "@angular/common/locales/th"; // Thai
import trTR from "@angular/common/locales/tr";
import viVn from "@angular/common/locales/vi";
import zhCN from "@angular/common/locales/zh-Hans"; // Simplified Chinese
import zhTW from "@angular/common/locales/zh-Hant";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { UserFacadeService } from "@core/facades/user-facade.service";
import { EventListenerService } from "@core/services/event-listener-service/event-listener.service";
import { AnalyticsFacadeService } from "@features/analytics/facades/analytics-facade.service";
import { AppConfigService } from "@find-hotels-app/shared/services/config/app-config/app-config.service";
import { AkamaiService, UhfService, UserStatusType } from "@ihg/common";
import { EndOfSession, LogService } from "@ihg/logging";
import { DeviceDetectorService } from "ngx-device-detector";
import { take, tap } from "rxjs/operators";

import { supportedLocalesMap } from "@shared/models/locales.enum";

@Component({
  selector: "find-hotels-root",
  templateUrl: "./app.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private endSessionController: AbortController;

  constructor(
    private userFacadeService: UserFacadeService,
    private uhfService: UhfService,
    private appConfigService: AppConfigService,
    private analyticsFacadeService: AnalyticsFacadeService,
    private zone: NgZone,
    private renderer: Renderer2,
    private logService: LogService,
    private deviceDetector: DeviceDetectorService,
    private akamaiService: AkamaiService
  ) {
    registerLocaleData(arAE, supportedLocalesMap.get("ar_AE"));
    registerLocaleData(deDE, supportedLocalesMap.get("de_DE"));
    registerLocaleData(enGB, supportedLocalesMap.get("en_GB"));
    registerLocaleData(enUS, supportedLocalesMap.get("en_US"));
    registerLocaleData(esUS, supportedLocalesMap.get("es_US"));
    registerLocaleData(frFR, supportedLocalesMap.get("fr_FR"));
    registerLocaleData(itIT, supportedLocalesMap.get("it_IT"));
    registerLocaleData(idID, supportedLocalesMap.get("in_ID"));
    registerLocaleData(jaJP, supportedLocalesMap.get("ja_JP"));
    registerLocaleData(koKR, supportedLocalesMap.get("ko_KR"));
    registerLocaleData(nlNL, supportedLocalesMap.get("nl_NL"));
    registerLocaleData(plPl, supportedLocalesMap.get("pl_PL"));
    registerLocaleData(ptBr, supportedLocalesMap.get("pt_BR"));
    registerLocaleData(ptPT, supportedLocalesMap.get("pt_PT"));
    registerLocaleData(ruRU, supportedLocalesMap.get("ru_RU"));
    registerLocaleData(thTh, supportedLocalesMap.get("th_TH"));
    registerLocaleData(trTR, supportedLocalesMap.get("tr_TR"));
    registerLocaleData(viVn, supportedLocalesMap.get("vi_VN"));
    registerLocaleData(zhCN, supportedLocalesMap.get("zh_CN"));
    registerLocaleData(zhTW, supportedLocalesMap.get("zh_TW"));
    registerLocaleData(esES, supportedLocalesMap.get("es_ES"));
    inject(EventListenerService);

    this.uhfService
      .bootstrap()
      .pipe(
        tap((userStatus: UserStatusType) => {
          this.zone.run(() => {
            console.debug("UHF status value", userStatus);
            this.userFacadeService.fetch(userStatus);
          });
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.analyticsFacadeService.commonData();
    this.initializeLogServices();
    this.uhfService
      .isLoaded$()
      .pipe(take(1))
      .subscribe(() => {
        this.uhfService.setReloadPageOptions(false, this.appConfigService.config.appName);
        this.uhfService.nonStick();
      });
  }

  ngAfterViewInit(): void {
    this.overrideBodyBGColor();
  }

  initializeLogServices(): void {
    this.logService.updateBrowserInfo({
      name: this.deviceDetector.browser ?? "",
      version: this.deviceDetector.browser_version ?? "",
      userAgent: this.deviceDetector.userAgent ?? "",
    });

    this.logService.updateAkamaiInfo({
      clientIP: this.akamaiService.clientIP,
      countryCode: this.akamaiService.countryCode,
      longitude: this.akamaiService.longitude.toString(10) ?? "",
      latitude: this.akamaiService.latitude.toString(10) ?? "",
      region: this.akamaiService.region,
      akamaiSubRegion: this.akamaiService.subRegion,
    });

    this.endSessionController = EndOfSession.addEndOfSessionListener(this.logService.postLogs.bind(this.logService));
  }

  overrideBodyBGColor(): void {
    this.renderer.setStyle(document.body, "background-color", "#ffffff");
  }

  ngOnDestroy() {
    this.endSessionController.abort();
  }
}
