import { Routes } from "@angular/router";
import { queryParamsGuard } from "@core/routes/guards/query-params.guard";

export const APP_ROUTES: Routes = [
  { path: "", redirectTo: "hotel-search", pathMatch: "full" },
  {
    path: "hotel",
    redirectTo: "hotel-search",
    pathMatch: "full",
  },
  {
    path: "hotel/list",
    redirectTo: "hotel-search",
    pathMatch: "full",
  },
  {
    path: "hotel/map",
    redirectTo: "hotel-search",
    pathMatch: "full",
  },
  {
    path: "hotel-search",
    loadChildren: () => import("@find-hotels-app/pages/hotel-search-page/hotel-search.routes").then((m) => m.srRoutes),
  },
  {
    path: "select-roomrate",
    loadChildren: () =>
      import("@find-hotels-app/pages/select-room-rate/components/room-rate-view/room-rate.routes").then(
        (m) => m.crrRoutes
      ),
    canActivate: [queryParamsGuard],
  },
  {
    path: "interstitial",
    loadChildren: () =>
      import("@find-hotels-app/pages/interstitial/interstitial.routes").then((m) => m.interstitialRoutes),
  },
  {
    path: "offers/last-minute-deals",
    loadChildren: () =>
      import("@find-hotels-app/pages/last-minute-deals/last-minute.routes").then((m) => m.lastMinuteRoutes),
    pathMatch: "full",
  },
  {
    path: "select-enhance-stay",
    loadChildren: () =>
      import("@pages/select-enhance-stay/components/enhance-stay-view/enhance-stay.routes").then((m) => m.eysRoutes),
  },
];
