import { ApiConfig } from "@ihg/army-services";
import { GqlProxyProviderConfig } from "@ihg/gql-proxy";
import { ApiConfig as HotelDetailsApiConfig } from "@ihg/hotel-details";
import { ILogConfig } from "@ihg/logging";

import { AppConfigService } from "../app-config.service";
import { AppConfig } from "../models/app-config.model";

export class AppConfigFactory {
  static getAppConfig(appConfigService: AppConfigService): AppConfig {
    return appConfigService.config;
  }

  static getLocale(appConfigService: AppConfigService): string {
    return appConfigService.formatLocale;
  }

  static getHotelDetailsConfig(appConfigService: AppConfigService): HotelDetailsApiConfig {
    const { country, language, environment, isChinaDomain } = appConfigService.config;
    return {
      environment,
      chinaRegion: isChinaDomain,
      country,
      language,
    };
  }

  static getApigeeApiConfig(appConfigService: AppConfigService): ApiConfig {
    const appConfig: AppConfig = appConfigService.config;
    return {
      environment: appConfig.environment,
      chinaRegion: appConfig.isChinaDomain,
    };
  }

  static getGqlProxyConfig(appConfigService: AppConfigService): GqlProxyProviderConfig {
    const appConfig: AppConfig = appConfigService.config;
    return {
      environment: appConfig.environment,
    };
  }

  static getLogConfig(appConfigService: AppConfigService): ILogConfig {
    return {
      ...appConfigService.config.log,
      baseURL: appConfigService.config.baseURL || "",
      appName: appConfigService.config.appName,
      appVersion: appConfigService.config.appVersion,
      environment: appConfigService.config.environment,
      chinaRegion: appConfigService.isChinaDomain,
    };
  }

  static getFinanceConfig(appConfigService: AppConfigService): ApiConfig {
    const { isChinaDomain, environment } = appConfigService.config;
    return {
      environment: environment,
      chinaRegion: isChinaDomain,
    };
  }
}
